import "./Home.css";
import { useState } from "react";
import ReleaseTable from "./ReleaseTable";

const Home = () => {

  const [currentDate, setCurrentDate] = useState(Date.now())
  // const [xDate, setXDate] = useState(new Date().setFullYear(2023,10,20))
  const [xDate, setXDate] = useState(new Date().setFullYear(2024,10,22))

  var diffMilliSec = xDate - currentDate;
  var diffDays = parseInt(diffMilliSec / 1000 / 60 / 60 / 24);

  return (
    <>
      { diffDays > 0
      ?<div className="diffDays">2024年11月22日まで残り{diffDays}日</div>
      : <div className="diffDays">残り日数の設定はありません</div>
      }
      <ReleaseTable />
    </>
  )
}

export default Home;
